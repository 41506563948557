/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Img from 'react-cool-img';

export default function ImgWithFallback(src, fallback, alt, style) {
  const webp = `https://storage.googleapis.com/find-me-sales-bucket${src.link}.webp`;
  const jpg = `https://storage.googleapis.com/find-me-sales-bucket${src.link}.jpg`;

  return (
    <picture style={src.style}>
      <source srcSet={webp} alt={src.alt} type="image/webp" />
      <Img src={jpg} alt={src.alt} loading="lazy" style={src.style} />
    </picture>
  );
}

// export default function ImgWithFallback(src, fallback, alt, style) {
//   const webp = `https://storage.googleapis.com/find-me-sales-bucket${src.link}.webp`;
//   const jpg = `https://storage.googleapis.com/find-me-sales-bucket${src.link}.jpg`;
//   // if (RENDER === 'SERVER') {
//   //   <img src={jpg} alt={src.alt} style={src.style} />;
//   // } else {
//   let img;
//   let width = 'inherit';
//   let height = 'inherit';
//   // if (process.env.NODE_ENV !== 'development') {
//   if (typeof window !== 'undefined') {
//     img = new Image();
//     img.onload = function () {
//       height = src.style.height;
//       width = src.style.width;
//     };
//     img.src = jpg;
//   }
//   // const webp = 'webp';
//   // const webp = src.src.substring(0, src.src.length - 3).concat(e);
//   return (
//     // <picture>
//     //   <source
//     //     srcSet={webp}
//     //     type="image/webp"
//     //     style={src.style}
//     //     loading="lazy"
//     //     width={`${src.style.width}`}
//     //     height={`${src.style.height}`}
//     //   />
//     //   <img
//     //     src={jpg}
//     //     alt={src.alt}
//     //     loading="lazy"
//     //     width={`${src.style.width}`}
//     //     height={`${src.style.height}`}
//     //     style={src.style}
//     //   />
//     // </picture>
//     <picture style={src.style}>
//       <source srcSet={webp} type="image/webp" />
//       <img src={jpg} alt={src.alt} loading="lazy" width="100%" height="100%" />
//     </picture>
//   );
//   // } else {
//   //   return (
//   //     <img
//   //       src={jpg}
//   //       alt={src.alt}
//   //       width={style.width}
//   //       height={style.height}
//   //       loading="lazy"
//   //       style={src.style}
//   //     />
//   //   );
//   // }
//   // }
// }
